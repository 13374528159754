import * as React from 'react';
import * as THREE from 'three';
import { DRACOLoader } from 'three/addons/loaders/DRACOLoader.js';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import coachProduct from '../animations/TOSS_CCenter.glb';

export function Coach() {
    const [selectedSection, setSelectedSection] = React.useState(null); // State to track which section is clicked
    const canvasContainerCoach = React.useRef(null); // To reference the Canvas Container
    const canvasCoach = React.useRef(null); // To reference the canvas

    // Modal close function
    const closeModal = () => setSelectedSection(null);

    React.useEffect(() => {
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 5000);
        const renderer = new THREE.WebGLRenderer({
            canvas: canvasCoach.current,
            antialias: true,
            alpha: true,
        });
        renderer.setSize(window.innerWidth, window.innerHeight);

        // Ambient light (softens shadows)
        const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
        scene.add(ambientLight);

        // Upper three lights
        const light = new THREE.DirectionalLight(0xffffff, 1);
        light.position.set(2, 5, 4);
        scene.add(light);

        const light1 = new THREE.DirectionalLight(0xffffff, 1);
        light1.position.set(2, 5, 3);
        scene.add(light1);

        const light2 = new THREE.DirectionalLight(0xffffff, 1);
        light2.position.set(2, 5, 3.5);
        scene.add(light2);

        // Bottom three lights
        const light3 = new THREE.DirectionalLight(0xffffff, 1);
        light3.position.set(2, -5, 4);
        scene.add(light3);

        const light4 = new THREE.DirectionalLight(0xffffff, 1);
        light4.position.set(2, -5, 3);
        scene.add(light4);

        const light5 = new THREE.DirectionalLight(0xffffff, 1);
        light5.position.set(2, -5, 3.5);
        scene.add(light5);

        // Load models
        const draco = new DRACOLoader();
        draco.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');

        const loader = new GLTFLoader();
        loader.setDRACOLoader(draco);

        let loadedCItem = null;
        let isDragging = false;
        let previousMousePosition = { x: 0, y: 0 };
        let lastTouchY = 0;
        let lastTouchX = 0;

        loader.load(coachProduct, (gltf) => {
            loadedCItem = gltf.scene;
            loadedCItem.scale.set(2.5, 2.5, 2.5); // Adjust scale
            loadedCItem.position.set(0, -0.5, 0); // Position towards the bottom

            scene.add(loadedCItem);
        }, undefined, (error) => {
            console.error('Error loading Coach Item:', error);
        });

        // Camera position
        camera.position.z = 5;

        // Check if the mouse is within the canvas boundaries
        const isMouseOverCanvas = (event) => {
            const rect = canvasContainerCoach.current.getBoundingClientRect();
            return event.clientX >= rect.left && event.clientX <= rect.right &&
                   event.clientY >= rect.top && event.clientY <= rect.bottom;
        };
        // Check if the touch is within the canvas boundaries
        const isTouchOverCanvas = (event) => {
            const rect = canvasContainerCoach.current.getBoundingClientRect();
            return (
                event.touches[0].clientX >= rect.left &&
                event.touches[0].clientX <= rect.right &&
                event.touches[0].clientY >= rect.top &&
                event.touches[0].clientY <= rect.bottom
            );
        };

        // Handle rotation
        const onDocumentMouseMove = (event) => {
            if (isDragging && loadedCItem && isMouseOverCanvas(event)) {
                const deltaX = event.clientX - previousMousePosition.x;
                const deltaY = event.clientY - previousMousePosition.y;

                const rotationSpeed = 0.003;
                loadedCItem.rotation.y += deltaX * rotationSpeed;
                loadedCItem.rotation.x += deltaY * rotationSpeed;

                previousMousePosition = { x: event.clientX, y: event.clientY };
            }
        };
        const onDocumentTouchMove = (event) => {
            if (isDragging && loadedCItem && isTouchOverCanvas(event)) {
                const touch = event.touches[0]; // Only using the first touch point
                const deltaX = touch.clientX - lastTouchX;
                const deltaY = touch.clientY - lastTouchY;
        
                // If the movement is more horizontal, trigger rotation, otherwise allow scrolling
                if (Math.abs(deltaX) > Math.abs(deltaY)) {
                    const rotationSpeed = 0.003;
                    loadedCItem.rotation.y += deltaX * rotationSpeed;
                    loadedCItem.rotation.x += deltaY * rotationSpeed;
                }
        
                lastTouchX = touch.clientX;
                lastTouchY = touch.clientY;
        
                // Prevent default scrolling behavior if rotating
                event.preventDefault();
            }
        };

        const onDocumentMouseDown = (event) => {
            if (isMouseOverCanvas(event)) {
                isDragging = true;
                previousMousePosition = { x: event.clientX, y: event.clientY };
            }
        };
        const onDocumentTouchStart = (event) => {
            // Store the initial touch position when touch starts
            const touch = event.touches[0]; // Use the first touch point
            lastTouchX = touch.clientX;
            lastTouchY = touch.clientY;
        
            if (isTouchOverCanvas(event)) {
                isDragging = true;
            }
        };

        const onDocumentMouseUp = () => {
            isDragging = false;
        };
        const onDocumentTouchEnd = () => {
            isDragging = false;
        };

       //Desktop/Mouse events
       window.addEventListener('mousemove', onDocumentMouseMove);
       window.addEventListener('mousedown', onDocumentMouseDown);
       window.addEventListener('mouseup', onDocumentMouseUp);
       //Mobile/Touch events
       window.addEventListener('touchmove', onDocumentTouchMove);
       window.addEventListener('touchstart', onDocumentTouchStart);
       window.addEventListener('touchend', onDocumentTouchEnd);


        function animate() {
            requestAnimationFrame(animate);
            renderer.render(scene, camera);
        }
        animate();

        return () => {
            window.removeEventListener('mousemove', onDocumentMouseMove);
            window.removeEventListener('mousedown', onDocumentMouseDown);
            window.removeEventListener('mouseup', onDocumentMouseUp);
            window.removeEventListener('touchmove', onDocumentTouchMove);
            window.removeEventListener('touchstart', onDocumentTouchStart);
            window.removeEventListener('touchend', onDocumentTouchEnd);
        };
    }, []);

    // Modal Content
    const renderModalContent = () => {
        switch (selectedSection) {
            case 'TIMEOUT':
                return (
                    <div className="modalContent">
                        <h4>TIMEOUT</h4>
                        <p>The coach’s devise comprises of a pushbutton or other device that is pressed to call a time out and alert the referee by activating the referee’s device/wristband. and in some implementation may The button can be pressed</p>
                    </div>
                );
            case 'WEARABLE':
                return (
                    <div className="modalContent">
                        <h4>WEARABLE</h4>
                        <p>The carry strap may be any suitable carry strap or similar component that allows the coach’s device to be carried, worn, or otherwise secured around a user’s wrist, neck, or other suitable area of the user.</p>
                    </div>
                );
            case 'LED DISPLAY':
                return (
                    <div className="modalContent">
                        <h4>LED DISPLAY</h4>
                        <p>The display is configured to inform the coach of the number of available time outs remaining (or other related information).</p>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div id="coachProduct">
            <div className='coachInfo'>
                <h1 className='coachTitle'>COACH</h1>
                <p>The invention equips at least one coach of each team with a transmitter. Each of the transmitters comprise a housing to store electronics therein and a button on the housing configured to transmit a signal over a network when the button is pressed by the respective coach. This signal transmits the calling of a timeout by the respective coach.</p>
                <div className='subjects'>
                    <section className='coachSubject' onClick={() => setSelectedSection('TIMEOUT')}>TIMEOUT</section>
                    <section className='coachSubject' onClick={() => setSelectedSection('WEARABLE')}>WEARABLE</section>
                    <section className='coachSubject' onClick={() => setSelectedSection('LED DISPLAY')}>LED DISPLAY</section>
                </div>
            </div>
            <div ref={canvasContainerCoach} className='canvasContainerCoach'>
                <canvas ref={canvasCoach} id="coachCanvas" width="100%" height="100%" />
            </div>
            {selectedSection && (
                <div className='modalContainerCoach'>
                    <div className='modal'>
                        <div className='modalBody'>
                            <button className='modalClose' onClick={closeModal}>X</button>
                            {renderModalContent()}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
