import * as React from 'react';
import { LandingScreen } from "../components/LandingScreen";
import { HorizontalNav } from "../components/HorizontalNav";
import { IntroVideo } from "../components/IntroVideo";
import { Overview } from "../components/Overview";
import { Problem } from "../components/Problem";
import { Referee } from '../components/Referee';
import { Coach } from '../components/Coach';
import { Footer } from "../components/Footer"

export default function Home() {

  const [showHNav, setShowHNav] = React.useState(false);

  React.useEffect(() => {
    const landingScreenHeight = document.getElementById('landingScreen').offsetHeight;

    const handleScroll = () => {
      const scrollY = window.scrollY;
      setShowHNav(scrollY > landingScreenHeight - 78)
    }

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [])

  return (
    <>
      <LandingScreen/>
      {showHNav && <HorizontalNav/>}
      <IntroVideo/>
      <Overview/>
      <Problem/>
      <Referee/>
      <Coach/>
      <Footer/>
    </>
  );
}
