import * as React from 'react';
import * as THREE from 'three';
import { DRACOLoader } from 'three/addons/loaders/DRACOLoader.js';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import refProduct from '../animations/TOSS_RCenter_compressed.glb';

export function Referee() {
    const canvasRef = React.useRef(null); // To reference the canvas
    const canvasContainerRef = React.useRef(null); // To reference the canvas Container
    const [selectedSection, setSelectedSection] = React.useState(null); // State to track which section is clicked

    // Modal close function
    const closeModal = () => setSelectedSection(null);

    React.useEffect(() => {
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        const renderer = new THREE.WebGLRenderer({
            canvas: canvasRef.current,
            antialias: true,
            alpha: true,
        });
        renderer.setSize(window.innerWidth, window.innerHeight);

        // Ambient light (softens shadows)
        const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
        scene.add(ambientLight);

        // Upper three lights
        const light = new THREE.DirectionalLight(0xffffff, 1);
        light.position.set(2, 5, 4);
        scene.add(light);

        const light1 = new THREE.DirectionalLight(0xffffff, 1);
        light1.position.set(2, 5, 3);
        scene.add(light1);

        const light2 = new THREE.DirectionalLight(0xffffff, 1);
        light2.position.set(2, 5, 3.5);
        scene.add(light2);

        // Bottom three lights
        const light3 = new THREE.DirectionalLight(0xffffff, 1);
        light3.position.set(2, -5, 4);
        scene.add(light3);

        const light4 = new THREE.DirectionalLight(0xffffff, 1);
        light4.position.set(2, -5, 3);
        scene.add(light4);

        const light5 = new THREE.DirectionalLight(0xffffff, 1);
        light5.position.set(2, -5, 3.5);
        scene.add(light5);

        // Load models
        const draco = new DRACOLoader();
        draco.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');

        const loader = new GLTFLoader();
        loader.setDRACOLoader(draco);

        let loadedRItem = null;
        let isDragging = false;
        let previousMousePosition = { x: 0, y: 0 };
        let lastTouchY = 0;
        let lastTouchX = 0;

        loader.load(refProduct, (gltf) => {
            loadedRItem = gltf.scene;
            loadedRItem.scale.set(2, 2, 2); // Adjust scale
            loadedRItem.position.set(0, 0, 0); // Position towards the bottom
            loadedRItem.traverse((child) => {
                if (child.isMesh) {
                    if (child.material && child.material.name === 'Toss_Wrist_Letters_Glow') {
                        child.material.transparent = false; // Disable transparency
                        child.material.alphaTest = 0.5; // Alpha threshold for transparency
                    }
                    child.castShadow = false; // Disable shadow casting
                    child.receiveShadow = false; // Disable receiving shadows
                }
            });

            scene.add(loadedRItem);
        }, undefined, (error) => {
            console.error('Error loading Referee Item:', error);
        });

        // Camera position
        camera.position.z = 5;

        // Check if the mouse is within the canvas boundaries
        const isMouseOverCanvas = (event) => {
            const rect = canvasContainerRef.current.getBoundingClientRect();
            return event.clientX >= rect.left && event.clientX <= rect.right && event.clientY >= rect.top && event.clientY <= rect.bottom;
        };
        // Check if the touch is within the canvas boundaries
        const isTouchOverCanvas = (event) => {
            const rect = canvasContainerRef.current.getBoundingClientRect();
            return (
                event.touches[0].clientX >= rect.left &&
                event.touches[0].clientX <= rect.right &&
                event.touches[0].clientY >= rect.top &&
                event.touches[0].clientY <= rect.bottom
            );
        };

        // Handle rotation
        const onDocumentMouseMove = (event) => {
            if (isDragging && loadedRItem && isMouseOverCanvas(event)) {
                const deltaX = event.clientX - previousMousePosition.x;
                const deltaY = event.clientY - previousMousePosition.y;

                const rotationSpeed = 0.003;
                loadedRItem.rotation.y += deltaX * rotationSpeed;
                loadedRItem.rotation.x += deltaY * rotationSpeed;

                previousMousePosition = { x: event.clientX, y: event.clientY };
            }
        };
        const onDocumentTouchMove = (event) => {
            if (isDragging && loadedRItem && isTouchOverCanvas(event)) {
                const touch = event.touches[0]; // Only using the first touch point
                const deltaX = touch.clientX - lastTouchX;
                const deltaY = touch.clientY - lastTouchY;
        
                // If the movement is more horizontal, trigger rotation, otherwise allow scrolling
                if (Math.abs(deltaX) > Math.abs(deltaY)) {
                    const rotationSpeed = 0.003;
                    loadedRItem.rotation.y += deltaX * rotationSpeed;
                    loadedRItem.rotation.x += deltaY * rotationSpeed;
                }
        
                lastTouchX = touch.clientX;
                lastTouchY = touch.clientY;
        
                // Prevent default scrolling behavior if rotating
                event.preventDefault();
            }
        };
        const onDocumentMouseDown = (event) => {
            if (isMouseOverCanvas(event)) {
                isDragging = true;
                previousMousePosition = { x: event.clientX, y: event.clientY };
            }
        };
        const onDocumentTouchStart = (event) => {
            // Store the initial touch position when touch starts
            const touch = event.touches[0]; // Use the first touch point
            lastTouchX = touch.clientX;
            lastTouchY = touch.clientY;
        
            if (isTouchOverCanvas(event)) {
                isDragging = true;
            }
        };

        const onDocumentMouseUp = () => {
            isDragging = false;
        };
        const onDocumentTouchEnd = () => {
            isDragging = false;
        };

        //Desktop/Mouse events
        window.addEventListener('mousemove', onDocumentMouseMove);
        window.addEventListener('mousedown', onDocumentMouseDown);
        window.addEventListener('mouseup', onDocumentMouseUp);
        //Mobile/Touch events
        window.addEventListener('touchmove', onDocumentTouchMove);
        window.addEventListener('touchstart', onDocumentTouchStart);
        window.addEventListener('touchend', onDocumentTouchEnd);


        function animate() {
            requestAnimationFrame(animate);
            renderer.render(scene, camera);
        }
        animate();

        return () => {
            window.removeEventListener('mousemove', onDocumentMouseMove);
            window.removeEventListener('mousedown', onDocumentMouseDown);
            window.removeEventListener('mouseup', onDocumentMouseUp);
            window.removeEventListener('touchmove', onDocumentTouchMove);
            window.removeEventListener('touchstart', onDocumentTouchStart);
            window.removeEventListener('touchend', onDocumentTouchEnd);
        };
    }, []);

    // Modal Content
    const renderModalContent = () => {
        switch (selectedSection) {
            case 'TIMEOUT':
                return (
                    <div className="modalContent">
                        <h4>TIMEOUT</h4>
                        <p>The display will be configured to inform a referee of the identity (e.g., locale/team color/name) of the team.</p>
                    </div>
                );
            case 'WEARABLE':
                return (
                    <div className="modalContent">
                        <h4>WEARABLE</h4>
                        <p>In some implementations, the wristband may comprise any suitable components in any suitable configuration such that the wristband can be worn on a user’s wrist or other suitable rea of the user. The wristband may comprise one or more straps or similar components that allow the wristband to be worn on a user’s wrist or other suitable area of the user.</p>
                    </div>
                );
            case 'LED DISPLAY':
                return (
                    <div className="modalContent">
                        <h4>LED DISPLAY</h4>
                        <p>The alert device may comprise any suitable components to alert and/or inform the referee that a timeout has been called using the coach’s device. The alert device will comprise a visual alert device and a display. The alert device will comprise components such as a tactile (e.g., vibrating) alert device and audible alert device. The lighting of the visual alert will be configured to light in any suitable way such as continuous, blinking, varying intensity, etc. to provide an indication related to a timeout called using the coach’s device.</p>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div id="refProduct">
            <div ref={canvasContainerRef} className='canvasContainerRef'>
                <canvas ref={canvasRef} id="refCanvas" width="100%" height="100%" />
            </div>
            <div className='refInfo'>
                <h1 className='refTitle'>REFEREE</h1>
                <p>The invention equips at least one referee with a portable receiver. The portable receiver is configured to receive wireless signals in response to the wireless signals transmitted by the transmitters and conjured to activate a distinctive alert in response to the received wireless signals. These signals indicate that a time out has been called and indicates which team called the timeout.</p>
                <div className='subjects'>
                    <section className='refSubject' onClick={() => setSelectedSection('TIMEOUT')}>TIMEOUT</section>
                    <section className='refSubject' onClick={() => setSelectedSection('WEARABLE')}>WEARABLE</section>
                    <section className='refSubject' onClick={() => setSelectedSection('LED DISPLAY')}>LED DISPLAY</section>
                </div>
            </div>
            {selectedSection && (
                <div className='modalContainerRef'>
                    <div className='modal'>
                        <div className='modalBody'>
                            <button className='modalClose' onClick={closeModal}>X</button>
                            {renderModalContent()}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
