import * as React from "react";

export function Footer(){

    return(
        <footer className="footer">
            <p>{'\u00A9'}&nbsp;{new Date().getFullYear()}&nbsp;T.O.S.S.&nbsp;|&nbsp;All Rights Reserved.</p>
            <p>Designed & Developed by Marcus Hill.</p>
        </footer>
    )
}